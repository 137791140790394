import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/affilate_mono_repo_vilkencykel/gatsby/src/components/mdxLayout.js";
import { graphql, Link } from "gatsby";
import ProductsDisplay from "../../components/productDisplay.js";
import CategoriesLinks from "../../components/categoriesLinks.js";
export const query = graphql`
  query {
    allMongodbStoreProducts(sort: {fields: popularity_score, order: DESC}, filter: {brand: { eq: "frappé" }}) {
      edges {
        node {
          	brand
			full_name
			id
			local_image_name
			model
			original_prices
			popularity_score
			prices
			product_id
			similair_products
			show_on_pages
			sub_product_type
			store
			url
			engine
			last_updated_date
			derived_properties {
				motor_type
				average_range_km
				number_of_gears
				battery_capacity
				frame_type
			}
			fields {
				brand_url
				full_name
				full_name_url
				model_url
			}
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "frappé-elcykel"
    }}>{`Frappé Elcykel`}</h1>
    <p>{`Välkommen till vår sida om Frappé Elcykel - den perfekta lösningen för dig som vill kombinera komfort, stil och miljövänlighet. Här hittar du all information du behöver om de olika serierna av elcyklar från Frappé, för att hjälpa dig att fatta det bästa valet för dina unika behov.`}</p>
    <h2 {...{
      "id": "frappé-fsc-400-dam"
    }}>{`Frappé FSC 400 Dam`}</h2>
    <p>{`Frappé FSC 400 Dam är en toppmodern elcykel som är perfekt anpassad för det moderna stadslivet. Den eleganta designen kombinerar stil med funktionalitet och är speciellt utformad för damer. Den är utrustad med en kraftfull Shimano STePS E5000-motor och Shimano Nexus 7-växlad navväxel. Denna cykel erbjuder inte bara en pålitlig och underhållsfri körupplevelse, utan även en imponerande 418Wh batterikapacitet som tar dig längre på varje laddning.`}</p>
    <h3 {...{
      "id": "nyckelfunktioner-för-frappé-fsc-400-dam"
    }}>{`Nyckelfunktioner för Frappé FSC 400 Dam`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Motor`}</strong>{`: Shimano STePS E5000`}</li>
      <li parentName="ul"><strong parentName="li">{`Växelsystem`}</strong>{`: Shimano Nexus 7-växlad navväxel`}</li>
      <li parentName="ul"><strong parentName="li">{`Batteri`}</strong>{`: 418Wh kapacitet`}</li>
      <li parentName="ul"><strong parentName="li">{`Hjulstorlek`}</strong>{`: 28 tum`}</li>
      <li parentName="ul"><strong parentName="li">{`Extrautrustning`}</strong>{`: Pakethållare och främre korg`}</li>
    </ul>
    <p>{`Den robusta konstruktionen och de hållbara komponenterna garanterar en säker och njutbar cykeltur varje gång. Tack vare de praktiska tilläggen som pakethållare och främre korg blir det enklare än någonsin att ta med allt du behöver. Frappé FSC 400 är inte bara en elcykel; den är en fullständig cykelupplevelse.`}</p>
    <h2 {...{
      "id": "köpguiden-för-frappé-elcyklar"
    }}>{`Köpguiden för Frappé Elcyklar`}</h2>
    <p>{`När du överväger att köpa en Frappé Elcykel, är det viktigt att tänka på dina specifika behov och hur du planerar att använda din cykel. Här är några punkter för att hjälpa dig välja rätt serie:`}</p>
    <h3 {...{
      "id": "för-det-moderna-stadslivet"
    }}>{`För det moderna stadslivet`}</h3>
    <p>{`Om du främst vill använda din cykel i stadsmiljö för pendling, är Frappé FSC 400 Dam ett utmärkt val. Med sin starka motor och höga batterikapacitet garanterar den en smidig resa genom stadens gator.`}</p>
    <h3 {...{
      "id": "för-längre-resor-och-utflykter"
    }}>{`För längre resor och utflykter`}</h3>
    <p>{`För de som planerar längre resor eller utflykter, är det viktigt att välja en cykel med större batterikapacitet och komfort. Frappé FSC 400 erbjuder allt detta, samt hållbara komponenter för en säker och bekväm upplevelse.`}</p>
    <h3 {...{
      "id": "för-stil-och-bekvämlighet"
    }}>{`För stil och bekvämlighet`}</h3>
    <p>{`Om stil och bekvämlighet är högt på din prioriteringslista, kommer Frappé FSC 400 Dam att imponera med sin eleganta design och sina anpassade funktioner för damer.`}</p>
    <h2 {...{
      "id": "varför-välja-frappé-elcyklar"
    }}>{`Varför välja Frappé Elcyklar?`}</h2>
    <p>{`Frappé Elcyklar kombinerar modern teknik med stilren design, vilket gör dem idealiska för en rad olika användningsområden. Med pålitlig prestanda, underhållsfria komponenter och hög batterikapacitet får du en elcykel som inte bara är praktisk utan också hållbar och miljövänlig.`}</p>
    <p>{`Upptäck det bästa från Frappé Elcyklar och hitta den perfekta modellen för dig. Gör ditt liv enklare och mer miljövänligt med en elcykel från Frappé – framtidens sätt att resa.`}</p>
    <ProductsDisplay data={props.data.allMongodbStoreProducts} location={props.location} mdxType="ProductsDisplay"></ProductsDisplay>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      